import React from "react"
import "../../styles/faq/FAQ.css"
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar"
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter"
import Faq from "react-faq-component"

const commonFaqs = {
  rows: [
    {
      title: "Why Nano / Micro Influencers? And what do you do?",
      content: `Paying celebrity influencers who have no connection with the brand makes it look like an advertisement. An average user on Instagram is NOT INFLUENCED by it. They are influenced by their friends/family (Nano-Micro Influencers). People who have about ~500-5000 followers. <br>
         <br>But Maintaining 500+ army of these nano-micro influencers is hard. It's too much manual work. Asking them to post, waiting, tracking, Ahhh!<br> <br>
         Influencerbit automates Micro Influencer marketing at scale. Our tool does all! Right from Capturing people who love your brand, Enticing them to post, tracking statistics and sending rewards!<br><br>
         We maintain & manage this powerful Army for you.
         `,
    },
    {
      title: "How do you capture Instagram Usernames?",
      content: `We give you Instagram Optional field which you can embed anywhere. At the order checkout or in your pop-up at the website or at order confirmation screen. Any pop-up or form solution which you are using, we integrate with them - Privy, Klaviyo, Wisepops, custom form, etc. <br><br>We embed Instagram Username optional field and whenever any user submits their instagram username, we pass this data to Influencerbit dashboard and enrich it with social data like follower count, post count, engagement rate, bio, Influencer grade, etc <br> `,
    },
    {
      title: "How do you entice the influencer to post about your brand? Is it automated?",
      content: `For any user, we get their social data - Follower count, Influencer Grade, Engagement rate, etc. And pass all this to your Email provider (Klaviyo, Mailchimp, etc). <br><br>
        So there you can create flows based on how influencial the user is. <br><Br>Say for 500-1000 followers, you entice them with 10% discount if they post about your brand on social. For influencers with 10k+ followers, you can give 25%. For 25k+ followers, you can set an alert so that a specific person can reach out and make long term relationship. This is ALL AUTOMATED. You just need to create flows and set rules once in your Email Sender.`,
    },
    // {
    //   title: "Which all social data do you get about the customer?",
    //   content: `Lorem ipsum dolor sit amet consectetur adipiscing elit. His sequi perferendis expect the ab rerum controls,quas minima modi dolorem! Quuisim laboram`,
    // },
    {
      title: "How do you detect & send rewards whenever any user mentions our brand?",
      content: `We have our integration with Instagram. In the dashboard, you need to connect your brand's instagram. Once connected, whenever anyone mentions/tags your brand, we get this data for any user who is in your dashboard.<br><br>We pass this event to your Mail Senders like Klaviyo, Mailchimp, etc and to any rewards integration you have (Yotpo). There you can create flows with trigger on Instagram Mention Event which we pass. Based on their influence you can send them custom rewards / discounts. For highly influencial people, you can give more discount. We have written in depth about these flows in our Guides. Also, feel free to reach out to us and book a free implementation call.`,
    },
    {
      title: "How are some of the brands using it?",
      content: `We have about 10 brands who are our beta customers at the moment and we are improving with their feedback.<br><br>Majority of them are collecting Instagram usernames at the Order Checkout page. Few of them have integrated this optional Instagram username field in their popups. About 20% of the people giving their emails are also giving their instagram username.<br><br>
        They then are using Klaviyo Flows to reachout whenever they submit the form and give incentives based on Influence. Some are only targeting influencers with followers from 1000-5000. Some have reward flows where they give user 50% discount code if they post 3 times or 5 times<br><br>After this, they have flows to detect mention and send the respective rewards. Most of our users at present are using Klaviyo. 20% of them are on Mailchimp and use that as ESP.`,
    },
    {
      title: "Do you track and maintain all the statistics and make reports?",
      content: `We store all the statistics and media. Which user, Mentioned you when, What their avg reach was and show this data w.r.t date in graphical format. You can see the trend and change in it.`,
    },

    // {
    //   title: "How this helps to run influencer marketing campaign?",
    //   content: `Lorem ipsum dolor sit amet consectetur adipiscing elit. His sequi perferendis expect the ab rerum controls,quas minima modi dolorem! Quuisim laboram`,
    // },
    {
      title: "Can I collect Instagram usernames on Checkout page for my customers?",
      content: `Quite a lot of brands are already doing this with Influencerbit. Yes you can. We have written a guide on it too. You can check it out <a href="https://help.influencerbit.com/en/articles/5633264-collect-your-customer-s-instagram-at-checkout-using-klaviyo" target="_blank">here</a>.`,
    },
    {
      title: "Give more details on your Privacy?",
      content: `Privacy is our top priority and we do everything by the book. The users are opting-in and giving their instagram username. We are only gathering public data and insights available through our integration with facebook. All our data is backed up and stored on DigitalOcean. It is encrypted and secure.`,
    },

    // {
    //   title: "I want to send reward to all users who mentioned my brand 3 times in the last 30 days? Can I do it on autopilot?",
    //   content: `Yes, you can do this and `,
    // }
  ],
}

const FAQComponent = (
    {
        title = "FAQs",
        data = commonFaqs,
        mt = false
    }
) => {

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
    padding: "10px",
  }

  return (
      <div
          className="container is-fluid faqContainer"
          style={{
            marginTop: 0
          }}
      >
        <div className="columns is-multiline is-mobile">
          <div className="column is-12 has-text-centered-tablet">
            <h3 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-black faqHeader">
              {title}
            </h3>
            <p className="has-text-black is-size-4-desktop is-size-6-mobile is-size-4-tablet mt-3">
              Have a doubt? Check if we have answered it below!
            </p>
          </div>

          <div className="column is-12">
            <Faq data={data} styles={styles}  />
          </div>
        </div>
      </div>
  )
}

export default FAQComponent
