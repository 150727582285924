import React from 'react'
import FAQ from '../../components/FAQ'
import PageSeo from '../../components/SEO'

function index() {
    return (
        <>
            <PageSeo pageTitle="Faq" />
            <FAQ/>
        </>
    )
}

export default index
