import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar";
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter";
import React from "react";
import FAQComponent from "./FAQComponent";

const FaqPageComponent = () => {

  return (
      <div className="FAQCol">
        <PricingNavbar />
        <FAQComponent />
        <div className="mt-6">
          <InfluencerFooter />
        </div>
      </div>
  )
}

export default FaqPageComponent